import React from 'react';
import Layout from 'templates/Layout/Layout';
import Container from 'components/atoms/Container/Container';
import Footer from 'components/organisms/Footer/Footer';
import CustomHeader from 'components/organisms/CustomHeader/CustomHeader';
import Text, { H3, H4 } from 'components/atoms/Typography';
import styled from 'styled-components';
import { breakPoints } from 'utils/variables';

const StyledContainer = styled.div`
  margin-bottom: 5rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-bottom: 12rem;
  }

  .text {
    text-align: justify;
  }
`;

const Page = () => (
  <Layout
    metaTitle="Polityka prywatności - Auto Serwis Kubeczek"
    metaDescription="Polityka prywatności naszego auto serwisu. Kubeczek auto serwis zajmuje się serwisowaniem i naprawą samochodów. Kompleksowa naprawa samochodów Rybnik."
  >
    <CustomHeader title="Polityka <b>prywatności</b>" />
    <StyledContainer as={Container}>
      <H3>Drogi Użytkowniku,</H3>
      <br />
      <Text>
        <p>
          W związku z tym, że od 25 maja 2018 roku ma zastosowanie Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
          2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz
          uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), dokonaliśmy zmian w stosowanej przez nas Polityce Prywatności.
          <br />
          <br />
          Niniejszy dokument określa zasady na jakich chronione są dane osobowe osób korzystających ze serwisu Auto - Części Adam Kubeczek (dalej:
          „Serwis”).
          <br />
          <br />
          Polityka prywatności opisuje rodzaj gromadzonych Danych Osobowych Użytkownika Serwisu, sposób ich gromadzenia, wykorzystania a także ich
          przechowywania oraz ewentualnego udostępniania, a także opisuje, jakie prawa przysługują Ci w związku z przetwarzaniem danych osobowych.
          <br />
          <br />
          Administrator jest obowiązany do ochrony prywatności Użytkowników Serwisu. Działając w tym celu Administrator dokłada wszelkich starań w
          celu zapewnienia Użytkownikowi Serwisu ochrony udzielonych danych osobowych w związku z korzystaniem z Serwisu i dokonywaniem zakupów.{' '}
          <br />
          <br />
          <b>Administratorem Pana/Pani danych osobowych jest</b> Adam Kubeczek, prowadzący działalność gospodarczą pod firmą „Auto - Części Adam
          Kubeczek”, ul. Wodzisławska 100, 44-218 Rybnik, NIP: 6422449501.
        </p>
        <br />
        <br />
        <H4>CELE I PODSTAWY PRZETWARZANIA</H4>
        <br />
        <p>
          1. <b>Umowa</b>
          <br />
          <br />W przypadku kontaktu w celu zawarcia umowy, Pana/Pani dane przetwarzamy{' '}
          <b>w celu podjęcia na Pana/Pani żądanie działań przed zawarciem umowy</b>, np. prowadzenia negocjacji lub przedstawienie oferty (podstawa z
          art. 6 ust. 1 lit. b RODO). Dane będą przetwarzane przez okres niezbędny do podjęcia działań na Pana/Pani żądanie. W przypadku nie zawarcia
          umowy po tym okresie dane zostaną usunięte, natomiast w przypadku zawarcia umowy będą w dalszym ciągu przetwarzane do momentu wykonania
          umowy oraz przedawnienia roszczeń wynikających z umowy na podstawie prawnie uzasadnionego interesu Administratora, którym jest obrona i
          dochodzenie roszczeń (art. 6 ust. 1 lit. b oraz f RODO).
          <br />
          <br />W przypadku zawarcia z Panem/Panią umowy, dane będą przetwarzane:
          <ul>
            <li>
              <b>w celu jej zawarcia i wykonania</b> (m.in. kontaktu w związku z jej wykonywaniem, potwierdzeniem płatności, udzielania rabatów,
              dostawy towarów, przesyłania powiadomień o realizacji zamówień) i na jej podstawie (podstawa z art. 6 ust. 1 lit. b RODO);
            </li>
            <li>
              <b>w celu realizacji procesu reklamacji</b> oraz zapewnienia prawidłowej obsługi klienta (podstawa z art. 6 ust. 1 lit. b RODO);
            </li>
            <li>
              <b>w celu realizacji obowiązków prawnych ciążących na Administratorze</b> m.in. obowiązków podatkowych, wynikających z przepisów kodeksu
              cywilnego, RODO, wystawiania faktur VAT, rozpatrywania reklamacji, spełnienia obowiązku informacyjnego (podstawa z art. 6 ust. 1 lit. c
              RODO);
            </li>
            <li>
              <b>w celach archiwalnych (dowodowych)</b> dla zabezpieczenia informacji na wypadek prawnej potrzeby wykazania faktów, na podstawie
              prawnie uzasadnionego interesu Administratora (podstawa z art. 6 ust. 1 lit. f RODO), którym jest archiwizacja dokumentacji,
            </li>
            <li>
              <b>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami</b>, na podstawie prawnie uzasadnionego interesu
              Administratora (podstawa z art. 6 ust. 1 lit. f RODO), którym jest wówczas ustalenie, dochodzenie i obrona przed roszczeniami,
            </li>
            <li>
              <b>w celu marketingu bezpośredniego własnych produktów i usług</b> na podstawie prawnie uzasadnionego interesu Administratora (podstawa
              z art. 6 ust. 1 lit. f RODO), którym jest marketing bezpośredni własnych produktów i usług.
            </li>
          </ul>
          <br />
          <br />
        </p>
        <p>
          2. <b>Formularz kontaktowy</b>
          <br />
          <br />
          <b>
            Przetwarzamy podane przez Pana/Panią w formularzu kontaktowym dane w celu realizacji obsługi zgłoszenia oraz odpowiedzi na pytanie, na
            podstawie naszego prawnie uzasadnionego interesu (podstawa z art. 6 ust. 1 lit. f RODO), którym jest kontakt z Klientami i udzielanie im
            odpowiedzi na pytania.{' '}
          </b>
          <br />
          <br />
        </p>
        <p>
          3. <b>Prowadzenie korespondencji tradycyjnej oraz za pośrednictwem e-mail</b>
          <br />
          <br />W przypadku kierowania przez Pana/Panią do Administratora korespondencji e-mail bądź tradycyjnej, która nie jest związana ze
          świadczeniem usług bądź wykonywania innej umowy, dane osobowe w niej zawarte są przetwarzane{' '}
          <b>w celu obsługi żądania lub zgłoszonego w korespondencji zapytania.</b>
          <br />
          <br />
          Podstawą przetwarzania w takim wypadku jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), który polega na
          prowadzeniu korespondencji oraz obsługi żądań i zapytań w związku z wykonywaną działalnością gospodarczą.
          <br />
          <br />
        </p>
        <p>
          4. <b>Kontakt telefoniczny</b>
          <br />
          <br />W przypadku kontaktu przez Pana/Panią drogą telefoniczną, w sprawach, które nie są związane ze świadczeniem usług na Pana/Pani rzecz
          bądź wykonywania innej umowy, dane osobowe przekazane przez Pana/Panią są przetwarzane{' '}
          <b>w celu obsługi żądania lub zgłoszonego zapytania</b>
          . <br />
          <br />
          Podstawą przetwarzania w takim wypadku jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), który polega na obsłudze
          żądań i zapytań w związku z wykonywaną działalnością gospodarczą. Administrator może żądać podania przez Pana/Panią danych niezbędnych do
          obsługi żądania, wówczas podanie takich danych jest obowiązkowe do obsługi żądania.
          <br />
          <br />
        </p>
        <p>
          5. <b>Kontakt pracowników kontrahenta lub Klienta</b>
          <br />
          <br />W przypadku, kiedy kontaktuje się Pan/Pani z nami telefonicznie lub mailowo w związku z zawartą z Pana/Pani pracodawcą umową lub
          czynnościami podejmowanymi na jego żądanie przed zawarciem umowy - uzyskane w ten sposób dane przetwarzamy{' '}
          <b>w celu wykonania zawartej umowy oraz podjęcia czynności na jego żądanie przed zawarciem umowy</b> (podstawa z art. 6 ust. 1 lit. b RODO),{' '}
          <b>a także w celu dochodzenia, obrony przed roszczeniami</b>, co stanowi prawnie uzasadniony interes Administratora polegający na ochronie
          jego praw (podstawa z art. 6 ust. 1 lit. f RODO).
          <br />
          <br />
          Jeżeli kontaktuje się Pan/Pani z nami w innej sprawie niż zawarta umowa, Pana/Pani dane przetwarzamy{' '}
          <b>w celu odpowiedzi na zadane pytanie lub rozwiązania sprawy</b>, z którą się do nas Pan/Pan zwraca. Podstawą przetwarzania jest prawnie
          uzasadniony interes Administratora polegający na udzieleniu odpowiedzi na zadane pytanie lub rozwiązanie sprawy w związku z prowadzoną
          działalnością gospodarczą (podstawa z art. 6 ust. 1 lit. f RODO).
        </p>
        <br />
        <br />
        <H4>ODBIORCY DANYCH</H4>
        <br />
        <p>
          1. <b>Powierzenia przetwarzania danych</b>
          <br />
          <br />
          <i>
            Pana/Pani osobowe możemy udostępniać podmiotom, z których korzystamy przy ich przetwarzaniu w szczególności przedsiębiorstwa świadczące
            usługi w zakresie dostawy towarów (kurierzy), obsługujące procesy niezbędne do przesyłania powiadomień o realizacji zamówień, usługodawcy
            świadczący usługi w zakresie rozliczeń należności.
          </i>
          <br />
          <br />
        </p>
        <p>
          2. <b>Przekazywanie danych do państw trzecich lub organizacji międzynarodowych</b>
          <br />
          <br />
          Nie przekazujemy Pana/Pani danych poza teren UE/ Europejskiego Obszaru Gospodarczego.
          <br />
          <br />
        </p>
        <br />
        <br />
        <H4>OKRES PRZECHOWYWANIA DANYCH</H4>
        <br />
        <p>
          Czas, przez jaki możemy przetwarzać Pana/Pani dane osobowe, jest uzależniony od podstawy prawnej stanowiącej legalną przesłankę
          przetwarzania danych osobowych przez Administratora. Nigdy nie będziemy przetwarzać danych osobowych ponad okres dłuższy niż wynika to z ww.
          podstaw prawnych. Stosownie do tego informujemy, że:
          <br />
          <ul style={{ listStyleType: 'decimal' }}>
            <li>
              w przypadku, gdy Administrator przetwarza dane osobowe na podstawie zgody, okres przetwarzania trwa do momentu wycofania tej zgody przez
              Pana/Panią,
            </li>
            <li>
              w przypadku, gdy Administrator przetwarza Pana/Pani dane osobowe pozyskane na podstawie Pana/Pani żądania do podjęcia przez
              Administratora działań przed zawarciem umowy, okres przetwarzania trwa przez czas niezbędny do podjęcia działań na Pana/Pani żądanie,
            </li>
            <li>
              w przypadku, gdy Administrator przetwarza dane osobowe, gdy jest to konieczne do wykonania umowy, okres przetwarzania trwa do momentu,
              kiedy ustanie możliwość́ dochodzenia roszczeń́ związanych z umową przez którąkolwiek ze stron,
            </li>
            <li>
              w przypadku, gdy Administrator przetwarza dane osobowe na podstawie uzasadnionego interesu administratora danych, okres przetwarzania
              trwa do momentu ustania ww. interesu (np. okres przedawnienia roszczeń cywilnoprawnych) lub do momentu sprzeciwienia się osoby, której
              dane dotyczą, dalszemu takiemu przetwarzaniu – w sytuacjach, gdy sprzeciw taki zgodnie z przepisami prawa przysługuje,
            </li>
            <li>
              w przypadku, gdy Administrator przetwarza dane osobowe, ponieważ jest to konieczne z uwagi na obowiązujące przepisy prawa, okresy
              przetwarzania danych w tym celu określają te przepisy.
            </li>
          </ul>
        </p>
        <br />
        <br />
        <H4>PANA/PANI UPRAWNIENIA</H4>
        <br />
        <p>
          Informujemy, że przysługuje Panu/Pani:
          <br />
          <ul style={{ listStyleType: 'lower-latin' }}>
            <li>prawo dostępu do swoich danych oraz otrzymania ich kopii</li>
            <li>prawo do sprostowania (poprawiania) swoich danych</li>
            <li>
              prawo do usunięcia danych. Jeżeli Pana/Pani zdaniem nie ma podstaw do tego, abyśmy przetwarzali Pana/Pani dane, możesz zażądać, abyśmy
              je usunęli.
            </li>
            <li>
              ograniczenia przetwarzania danych Może Pan/Pani zażądać, abyśmy ograniczyli przetwarzanie Pana/Pani danych osobowych wyłącznie do ich
              przechowywania lub wykonywania uzgodnionych z Panem/Panią działań, jeżeli Pana/Pani zdaniem mamy nieprawidłowe dane na Pana/Pani temat
              lub przetwarzamy je bezpodstawnie; lub nie chce Pan/Pani, żebyśmy je usunęli, bo są Panu/Pani potrzebne do ustalenia, dochodzenia lub
              obrony roszczeń; lub na czas wniesionego przez Pana/Panią sprzeciwu względem przetwarzania danych.
            </li>
            <li>
              prawo do wniesienia sprzeciwu wobec przetwarzania danych: Sprzeciw „marketingowy”. Ma Pan/Pani prawo sprzeciwu wobec przetwarzania
              Pana/Pani danych w celu prowadzenia marketingu bezpośredniego. Jeżeli skorzysta Pan/Pani z tego prawa – zaprzestaniemy przetwarzania
              danych w tym celu. Sprzeciw z uwagi na szczególną sytuację. Ma Pan/Pani prawo sprzeciwu wobec przetwarzania Pana/Pani danych na
              podstawie prawnie uzasadnionego interesu w celach innych niż marketing bezpośredni, a także, gdy przetwarzanie jest nam niezbędne do
              wykonania zadania realizowanego w interesie publicznym lub do sprawowania powierzonej nam władzy publicznej. Powinien Pan/Pani wtedy
              wskazać nam Pana/Pani szczególną sytuację, która Pana/Pani zdaniem uzasadnia zaprzestanie przez nas przetwarzania objętego sprzeciwem.
              Przestaniemy przetwarzać Pana/Pani dane w tych celach, chyba że wykażemy, że podstawy przetwarzania przez nas Pana/Pani danych są
              nadrzędne wobec Pana/Pani praw lub też że Pana/Pani dane są nam niezbędne do ustalenia, dochodzenia lub obrony roszczeń.
            </li>
            <li>
              prawo do przenoszenia danych: Ma Pan/Pani prawo otrzymać od nas w ustrukturyzowanym, powszechnie używanym formacie nadającym się do
              odczytu maszynowego (np. format „.csv” dane osobowe Pana/Pani dotyczące, które nam Pan/Pani dostarczył na podstawie umowy lub Pana/Pani
              zgody. Może Pan/Pani też zlecić nam przesłanie tych danych bezpośrednio innemu podmiotowi.
            </li>
            <li>
              prawo do wniesienia skargi do organu nadzorczego Jeżeli uważa Pan/Pani, że przetwarzamy Pana/Pani dane niezgodnie z prawem, może
              Pan/Pani złożyć w tej sprawie skargę do Prezesa Urzędu Ochrony Danych Osobowych.
            </li>
            <li>
              prawo do cofnięcia zgody na przetwarzanie danych osobowych W każdej chwili ma Pan/Pani prawo cofnąć zgodę na przetwarzanie tych danych
              osobowych, które przetwarzamy na podstawie Pana/Pani zgody. Cofnięcie zgody nie będzie wpływać na zgodność z prawem przetwarzania,
              którego dokonano na podstawie Pana/Pani zgody przed jej wycofaniem.
            </li>
          </ul>
        </p>
        <p>
          W każdej chwili ma Pan/Pani prawo cofnąć zgodę na przetwarzanie tych danych osobowych, które przetwarzamy na podstawie Pana/Pani zgody.
          Cofnięcie zgody nie będzie wpływać na zgodność z prawem przetwarzania, którego dokonano na podstawie Pana/Pani zgody przed jej wycofaniem.
        </p>

        <p>
          Jeżeli uważa Pan/Pani, że przetwarzamy Pana/Pani dane niezgodnie z prawem, może Pan/Pani złożyć w tej sprawie skargę do Prezesa Urzędu
          Ochrony Danych Osobowych.
        </p>

        <p>
          W przypadku chęci realizacji powyższych praw, prosimy o kontakt osobisty, za pośrednictwem poczty tradycyjnej bądź e-mail z wykorzystaniem
          następujących danych:
          <br />
          <b>Adam Kubeczek, prowadzący działalność gospodarczą pod firmą „Auto - Części Adam Kubeczek”, ul. Wodzisławska 100, 44-218 Rybnik. </b>
        </p>
        <br />
        <br />
        <H4>DOBROWOLNOŚĆ PODANIA DANYCH OSOBOBOWYCH</H4>
        <br />
        <p>
          Podanie danych w związku z obsługą żądania i zgłoszonego zapytania, przedstawionych telefonicznie, za pomocą korespondencji tradycyjnej lub
          korespondencji e-mail, jest niezbędne do obsługi pytania i odpowiedzi na nie oraz rozwiązania sprawy, a ich niepodanie będzie skutkowało
          brakiem możliwości przesłania odpowiedzi lub załatwienia sprawy.{' '}
        </p>
        <p>
          W przypadku, kiedy kontaktujesz się z nami w celu zadanie pytania lub wskazania sprawy do rozwiązania podanie przez Pana/Pani danych
          umożliwiających kontakt zwrotny jest dobrowolne, jednak konieczne do udzielenia odpowiedzi na pytanie lub rozwiązania przedstawionej sprawy.{' '}
        </p>
        <p>
          <i>
            Podanie danych wskazanych w formularzu kontaktowym jako obowiązkowe jest niezbędne do obsługi pytania i odpowiedzi na nie, a ich
            niepodanie będzie skutkowało brakiem możliwości przesłania zapytania.{' '}
          </i>
        </p>
        <p>
          <i>
            Podanie danych w związku z zawarciem, realizacją i wykonaniem umowy jest dobrowolne, ale konieczne do właściwej realizacji usługi i
            wykonania umowy. Konsekwencją niepodania tych danych będzie brak możliwości zawarcia umowy z Administratorem.
          </i>
        </p>
        <br />
        <br />
        <H4>ZAUTOMATYZOWANE PODEJMOWANIE DECYZJI</H4>
        <br />
        <p>Informuję, iż nie będziemy podejmować wobec Pana/Pani decyzji w sposób zautomatyzowany, w tym nie będziesz podlegał profilowaniu.</p>
      </Text>
    </StyledContainer>
    <Footer />
  </Layout>
);

export default Page;
